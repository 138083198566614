.Container {
    height    : max-content;
    min-height: 100vh;
    width     : 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;

    background-color: rgb(11 11 18 / 20%);

    position: fixed;
    z-index : 10;
    top: 0;
    left: 0;
}

.Modal {
    position: fixed;
    z-index : 10;

    width         : 568px;
    padding-top   : 33px;
    padding-bottom: 24px;

    background   : #FFFFFF;
    border-radius: 8px;
    box-shadow   : 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.ExitIcon {
    position: absolute;
    right   : 8px;
    top     : 8px;

    font-size: 18px;
    width    : 25px;
    height   : 25px;

    cursor: pointer;
    color : #6b6b70;
}

.Content {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.Text {
    font-size  : 20px;
    line-height: 1.6;
    font-weight: normal;

    white-space: pre-line;

    color: #0b0b12;
}

.WarningIcon {
    width : 39px;
    height: 39px;

    border       : solid 1.5px #0b0b12;
    border-radius: 50%;

    font-size  : 20px;
    font-weight: bold;

    display        : flex;
    justify-content: center;
    align-items    : center;

    margin-bottom: 17px;
}

.Button {
    font-size  : 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align : center;
    color      : #fff;

    padding      : 14px;
    margin-bottom: 55px;
    margin-top   : 12px;

    border-radius   : 10px;
    background-color: #ec008c;
}

.Link {
    font-size  : 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align : center;
    color      : #6b6b70;

    background: transparent;
    text-decoration: underline;
}

@media(max-width: 500px) {
    .Modal {
        width: 90%;
        left : 5%;
    }
}