@font-face {
    font-family: Panton;
    src: url(assets/font/panton_regular.otf);
    font-weight: normal;
}

@font-face {
    font-family: Panton;
    src: url(assets/font/panton_bold.otf);
    font-weight: bold;
}

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

body {
    background-color: rgba(11,11,18,1);
    font-family: Panton;   
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: baseline;
    min-width: 320px;
}

#root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
} */